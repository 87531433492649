<template>
  <v-card>
    <v-card-title class="primary"><span class="white--text">Receipt By Reference Number</span></v-card-title>
    <v-card-actions>
      <v-text-field
        clearable
        v-model="referenceNumber"
        label="Receipt Reference Number"
        :prepend-icon="icons.mdiBarcode"
        type="text"
        color="black"
        counter="10"
        @keypress.enter="searchReferenceNumber"
      ></v-text-field>
    </v-card-actions>
    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn @click="searchReferenceNumber" color="primary" large>Search</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mdiBarcode } from "@mdi/js";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      referenceNumber: '',
      icons: {
        mdiBarcode
      }
    };
  },
  methods: {
    ...mapActions(["getReceiptWithReferenceNumber", "snackAlert"]),
    searchReferenceNumber() {
      if (this.referenceNumber && this.referenceNumber.length === 10) {
        this.getReceiptWithReferenceNumber(this.referenceNumber).then(hasReceipt => {
          if (hasReceipt) this.$store.commit("setShowDialogue", true);
        });
      } else {
        this.snackAlert({
          message: `Wrong reference number`
        });
      }
    }
  }
};
</script>
