import { axios, updateAuthToken } from "../plugins/axios";
import router from "./../router";

export default {
  errorHandler({ dispatch }, error) {
    if (!error.status) {
      return dispatch("snackAlert", { message: error });
    }
    switch (error.status) {
      case 401:
        router.push({ name: "logout" });
        dispatch("snackAlert", {
          message: "Authorization Problem. Please login again. ¯\\_(ツ)_/¯"
        });
        break;
      default:
        console.log(error);
        dispatch("snackAlert", { message: error.message });
    }
  },

  snackAlert({ commit }, payload) {
    const { message, type = "error", duration = 1500 } = payload;
    commit("snackBar", { message, type, duration });
  },

  login({ commit, dispatch }, value) {
    const { user } = value;
    return axios
      .post("/login", user)
      .then(res => {
        if (res.data.response) {
          commit("setUserToken", res.data.token);
          commit("setUserRole", res.data.role);
          commit("setUserApp", res.data.app);
          commit("setIsAuthenticated", true);
          commit("setUserName", user.userName);
          localStorage.isAuthenticated = true;
          localStorage.user = JSON.stringify({
            userName: user.userName,
            role: res.data.role,
            app: res.data.app,
            userToken: res.data.token
          });
          updateAuthToken(res.data.token);
          return { role: res.data.role, app: res.data.app };
        } else {
          dispatch("snackAlert", { message: res.data.message });
        }
      })
      .catch(err => {
        dispatch("errorHandler", err.response.data);
      });
  },

  logout({ commit }) {
    commit("setUserToken", "");
    commit("setIsAuthenticated", false);
    commit("setUserRole", "");
    commit("setUserApp", "");
    commit("setUserName", "");
    localStorage.removeItem("user");
    localStorage.removeItem("selectedApp");
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("token");
    router.push({ name: "login" });
  },

  setTheme({ commit }, value) {
    localStorage.darkTheme = value;
    commit("setDarkTheme", !value);
  },

  dashboarderStatistics({ commit, dispatch }, value) {
    return axios
      .post("/dashboarderStatistics", value)
      .then(res => {
        if (res.data.response) {
          return res.data;
        } else {
          dispatch("snackAlert", { message: res.data.message });
        }
      })
      .catch(err => {
        dispatch("errorHandler", err.response.data);
      });
  },

  getOneReceipt({ commit, dispatch }, value) {
    const params = value ? value : "dashboard";
    return axios
      .get(`/getOneReceipt?from=${params}`)
      .then(res => {
        if (res.data.response) {
          const usersCampaigns = res.data.receipt.campaignIds.map(i => parseFloat(i.id));
          commit("setUsersCampaigns", usersCampaigns);
          commit("setCurrentReceipt", res.data.receipt);
          if (res.data.prevPartcipations)
            commit("setPrevPartcipations", res.data.prevPartcipations);
          if (res.data.receipt.processHistory)
            commit("setProcessHistory", res.data.receipt.processHistory);
          if (res.data.paymentHistories) commit("setPaymentHistory", res.data.paymentHistories);
          if (res.data.deviceInfo) commit("setDeviceInfo", res.data.deviceInfo);
          if (value && res.data.receipt.retailer !== 15) {
            commit("mutateEnableProducts", true);
          }
          return res.data;
        } else {
          dispatch("snackAlert", { message: res.data.message });
        }
      })
      .catch(err => {
        dispatch("errorHandler", err.response.data);
      });
  },

  getDashboardData({ commit, dispatch }, value) {
    return axios
      .get("/getDashboardData")
      .then(res => {
        if (res.data.response) {
          localStorage.campaigns = JSON.stringify(res.data.campaigns);
          localStorage.retailers = JSON.stringify(res.data.retailers);
          localStorage.products = JSON.stringify(res.data.products);
          localStorage.otherProducts = JSON.stringify(res.data.otherProducts);
          localStorage.rejectReasons = JSON.stringify(res.data.rejectReasons);
          localStorage.reEvaluationMessages = JSON.stringify(res.data.reEvaluationMessages);
          return res.data;
        } else {
          dispatch("snackAlert", { message: res.data.message });
        }
      })
      .catch(err => {
        dispatch("errorHandler", err.response.data);
      });
  },

  processReceipt({ commit, dispatch }, value) {
    const { status, payload } = value;
    const endpoint =
      status === "REJECTED"
        ? "/rejectReceipt"
        : status === "SKIP"
          ? "/skipReceipt"
          : status === "REEVALUATE"
            ? "/reEvaluteReceipt"
            : status === "GOODWILL"
              ? "/goodWill"
              : "/processReceipt";

    return axios
      .post(endpoint, payload)
      .then(res => {
        if (res.data.response) {
          return res.data;
        } else {
          dispatch("snackAlert", { message: res.data.message });
        }
      })
      .catch(err => {
        dispatch("errorHandler", err.response.data);
      });
  },

  updateReceiptDetails({ dispatch }, value) {
    const { payload } = value;
    const endpoint = "/updateReceiptDetails";

    return axios
      .post(endpoint, payload)
      .then(res => {
        if (res.data.response) {
          return res.data;
        } else {
          dispatch("snackAlert", { message: res.data.message });
        }
      })
      .catch(err => {
        dispatch("errorHandler", err.response.data);
      });
  },

  dropReceipt({ commit, dispatch }, value) {
    return axios
      .post("/dropReceipt", { receiptToken: value })
      .then(res => {
        if (res.data.response) {
          return res.data;
        } else {
          dispatch("snackAlert", { message: res.data.message });
        }
      })
      .catch(err => {
        dispatch("errorHandler", err.response.data);
      });
  },

  getReceiptWithReferenceNumber({ commit, dispatch }, value) {
    return axios
      .get(`/getReceiptWithReferenceNumber?referenceNumber=${value}`)
      .then(res => {
        if (res.data.response) {
          const usersCampaigns = res.data.receipt.campaignIds.map(i => parseFloat(i.id));
          commit("setUsersCampaigns", usersCampaigns);
          commit("setCurrentReceipt", res.data.receipt);
          commit("setPrevPartcipations", res.data.prevPartcipations);
          commit("setUsedReceiptImageUrl", res.data.usedReceiptImageURL);
          commit("setUsedRereferenceNumber", res.data.receipt.usedRereferenceNumber);
          commit("setProcessHistory", res.data.receipt.processHistory);
          commit("setPaymentHistory", res.data.paymentHistories);
          commit("setDeviceInfo", res.data.deviceInfo);
          if (res.data.receipt.retailer !== 15) {
            commit("mutateEnableProducts", true);
          }
          return res.data;
        } else {
          dispatch("snackAlert", { message: res.data.message });
        }
      })
      .catch(err => {
        dispatch("errorHandler", err.response.data);
      });
  },

  getReceiptsWithConsumerInfo({ commit, dispatch }, value) {
    return axios
      .get(`/getReceiptsWithConsumerInfo?${value}`)
      .then(res => {
        if (res.data.response) {
          return res.data;
        } else {
          dispatch("snackAlert", { message: res.data.message });
        }
      })
      .catch(err => {
        dispatch("errorHandler", err.response.data);
      });
  },

  getReceiptsWithPaymentRef({ commit, dispatch }, value) {
    return new Promise((resolve, reject) => {
      axios.get(`/getPaymentDetailWithReferenceNumber?paymentReferenceNumber=${value}`)
        .then(res => {
          if (res.data.result && res.data.result.length > 0) {
            resolve(res.data)
          } else {
            reject(res.data);
          }
        }).catch(err => {
          reject(err.response.data);
        })
    })
  },

  forgotPassword: ({ commit, dispatch }, value) => {
    const { params } = value;
    return axios
      .post("/forgetPassword", params)
      .then(res => {
        if (res.data.response) {
          return res.data;
        } else {
          dispatch("snackAlert", { message: res.data.message });
        }
      })
      .catch(err => {
        dispatch("errorHandler", err.response.data);
      });
  },

  resetPassword: ({ commit, dispatch }, value) => {
    const { params } = value;
    return axios
      .post("/updatePassword", params)
      .then(res => {
        if (res.data.response) {
          return res.data;
        } else {
          dispatch("snackAlert", { message: res.data.message });
        }
      })
      .catch(err => {
        dispatch("errorHandler", err.response.data);
      });
  }
};
