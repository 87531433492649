<template>
  <v-col v-if="userRole !== 'OPERATOR' && prevPartcipations !== []">
    <v-card v-for="(item, idx) of participations" :key="idx">
      <div>
        <v-card-text class="pt-5 pb-0 d-flex align-center flex-wrap body-1">
          <h4>{{ `Receipt Details - ${item.referenceNumber}` }}</h4>
        </v-card-text>
        <v-simple-table class="mt-3">
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  Upload
                </th>
                <th>
                  Processed
                </th>
                <th>
                  Receipt
                </th>
                <th>
                  Receipt ID
                </th>
                <th>
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ getDateTime(item.createdAt) }}
                </td>
                <td>
                  {{ getDateTime(item.processedDateTime) }}
                </td>
                <td>
                  {{ getDateTime(item.receiptDateTime) }}
                </td>
                <td>
                  {{ item.receiptId }}
                </td>
                <td>
                  {{ item.total }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-data-table :headers="participationsHeaders" :disable-pagination="true" :hide-default-footer="true"
          :items="item.participations" dense>
          <template v-slot:item.campaignName="{ item }">
            {{ `${item.campaignId} - ${getCampaignName(item.campaignId)}` }}
          </template>
          <template v-slot:item.status="{ item }">
            {{ getOutput(item.output) }}
          </template>
        </v-data-table>
        <v-expansion-panels class="my-3 w-100">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h2>Receipt Image</h2>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div>
                <img crossorigin='anonymous' :src="item.receiptImageURL" class="mb-3 mx-auto" height="100%" max-width="500px"/>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-card>
  </v-col>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    const campaigns = localStorage.campaigns ? JSON.parse(localStorage.campaigns) : [];

    return {
      campaigns,
      participationsHeaders: [
        { text: "Campaign", value: 'campaignName', },
        { text: "Participation #", value: "participationNumber" },
        { text: "Status/Output", value: "status" },
      ]
    };
  },
  computed: {
    ...mapGetters(["userRole", "prevPartcipations"]),
    participations() {
      let newb = this.prevPartcipations
      newb.sort((a, b) => a.createdAt < b.createdAt)
      return newb
    }
  },
  mounted() {
    // Sorting from newest to oldest
    // this.prevPartcipations.sort((a,b) => a.receiptDateTime < b.receiptDateTime)
  },
  methods: {
    getDateTime(date) {
      return moment.parseZone(date).format("DD/MM/YYYY, HH:mm:ss");
    },
    getCampaignName(campaignId) {
      const campaign = this.campaigns.find(campaign => campaign.id == campaignId)
      return campaign.dashboardTitle ? `${campaignId} - ${campaign.dashboardTitle}` : `${campaignId} - ${campaign.title}`
    },
    getOutput(output) {
      if (output.cashback) {
        return `Cashback: ${output.cashback}`
      } else {
        return `Reject: ${output.rejectReason}`
      }
    },
  }
};
</script>
