<template>
  <v-card>
    <v-card-title class="pb-0">Competitor Products</v-card-title>
    <div>
      <v-col sm="sm-10">
        <span>
          <span class="mr-2">Add a new Product</span>
          <v-btn icon @click.stop.prevent="addProduct()" :disabled="disabled">
            <v-icon>{{ icons.mdiPlus }}</v-icon>
          </v-btn>
        </span>
        <!-- <v-autocomplete label="Products" :prepend-icon="icons.mdiFormatListText" :items="selectableOtherProducts"
          item-text="ean" item-value="id" max-height="auto" search-input.sync="ean" multiple :filter="searchProduct"
          :disabled="disabled">
          <template v-slot:item="data">
            <div style="width:100%;">
              <v-list-item @click.native.stop.prevent="addProduct(data)" class="d-flex justify-space-between">
                <div>
                  <span style="display:inline-block;min-width:18ch;">{{ data.item.categories }}</span>
                  <v-chip class="ma-2" color="blue" text-color="white">
                    <span class="black--text">{{ data.item.name }}</span>
                  </v-chip>
                  <v-chip class="ma-2" color="blue" text-color="white">
                    <span class="black--text">{{ data.item.brand }}</span>
                  </v-chip>
                </div>
                <span>
                  <v-btn icon @click.stop.prevent="addProduct(data, true)">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn icon @click.stop.prevent="removeProduct(data, true)">
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </span>
              </v-list-item>
            </div>
          </template>
</v-autocomplete> -->
        <v-data-table dense :items="otherProducts" :disable-pagination="true" :hide-default-footer="true"
          :headers="headers" no-data-text="No Products Selected" :item-key="new Date().getMilliseconds().toString()"
          class="elevation-1">
          <template v-slot:[`item.brand`]="{ item }">
            <div>
              <v-autocomplete label="Brands" v-model="item.brand" :items="getBrands()" item-text="brand" item-value="brand" max-height="auto"
                search-input.sync="brand" :filter="searchBrand" dense>
                <template v-slot:item="data">
                  <div style="width: 100%">
                    <div>
                      <span style="display: inline-block; min-width: 18ch">{{ data.item.brand }}</span>
                    </div>
                  </div>
                </template>
              </v-autocomplete>
              <!-- <v-select class="mt-3" required outlined :items="getBrands()" label="Brand" v-model="item.brand"
                :prepend-icon="icons.mdiMapMarker" :disabled="disabled" dense></v-select> -->
            </div>
          </template>
          <template v-slot:[`item.categories`]="{ item }">
            <div>
              <v-select class="mt-3" required outlined :items="getCategories(item)" multiple label="Categories"
                v-model="item.categories" :prepend-icon="icons.mdiMapMarker" :disabled="disabled" dense></v-select>
            </div>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <div>
              <v-text-field required @input="changePrice($event, item)" v-model="item.price"></v-text-field>
            </div>
          </template>
          <template v-slot:[`item.rppData`]="{ item }">
            <div>
              <v-select class="mt-3" required outlined :items="rppItems" label="RPP" v-model="item.rppData"
                :prepend-icon="icons.mdiMapMarker" :disabled="disabled" dense></v-select>
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon small class="mr-2" @click="addProduct(item, true)">{{ icons.mdiPlus }}</v-icon>
            <v-icon small @click="removeProduct(item, true)">{{ icons.mdiMinus }}</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </div>
  </v-card>
</template>

<script>
import { mdiPlus, mdiMinus, mdiFormatListText } from "@mdi/js";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["currentReceipt"])
  },
  data() {
    // const products = localStorage.products ? JSON.parse(localStorage.products) : [];
    const selectableOtherProducts = localStorage.otherProducts ? JSON.parse(localStorage.otherProducts) : [];
    const rppItems = (this.$store.getters.currentReceipt.rppData
      ? this.$store.getters.currentReceipt.rppData.concat([{ item: 'none' }])
      : [{ item: 'none' }]).map(item => item.item);

    return {
      selectableOtherProducts,
      // products,
      rppItems,
      brandCategories: {
        FINISH: ['FABRIC&HOME'],
        SOMAT: ['FABRIC&HOME'],
        'BOSQUE VERDE': ['FABRIC&HOME'],
        MISTOL: ['FABRIC&HOME'],
        FROSCH: ['FABRIC&HOME'],
        WIPP: ['FABRIC&HOME'],
        DIXAN: ['FABRIC&HOME'],
        COLON: ['FABRIC&HOME'],
        SKIP: ['FABRIC&HOME'],
        VERNEL: ['FABRIC&HOME'],
        MIMOSIN: ['FABRIC&HOME'],
        'FLOR GEL ELIXIR DELUXE': ['FABRIC&HOME'],
        SENSODYNE: ['ORAL CARE'],
        COLGATE: ['ORAL CARE'],
        PARODONTAX: ['ORAL CARE'],
        PHILIPS: ['ORAL CARE', 'APPLIANCES'],
        FAIRYWILL: ['ORAL CARE'],
        'CARREFOUR RB': ['ORAL CARE'],
        'AMZ BRANDS': ['ORAL CARE'],
        DELIPLUS: ['ORAL CARE', 'GROOMING', 'GROOMING', 'HAIR CARE', 'DIAPERS', 'WIPES'],
        AXE: ['GROOMING'],
        WILLIAMS: ['GROOMING'],
        REXONA: ['GROOMING'],
        WILKINSON: ['GROOMING', 'GROOMING'],
        BIC: ['GROOMING', 'GROOMING'],
        "L'OREAL MEN EXPERT": ['GROOMING'],
        BULLDOG: ['GROOMING'],
        'NIVEA MEN': ['GROOMING'],
        NIVEA: ['BEAUTY'],
        "L'OREAL": ['BEAUTY'],
        'BELLA AURORA': ['BEAUTY'],
        'FRUCTIS ': ['HAIR CARE'],
        ELVIVE: ['HAIR CARE'],
        TRESSEMME: ['HAIR CARE'],
        'ORIGINAL REMEDIES': ['HAIR CARE'],
        FRUCTIS: ['HAIR CARE'],
        'HERBAL ESSENCES - JAR (MASK 250ML O 450ML)': ['HAIR CARE'],
        'HERBAL ESSENCES SIN SULFATOS': ['HAIR CARE'],
        'HAIR FOOD': ['HAIR CARE'],
        SYOSS: ['HAIR CARE'],
        'PHILIPS 9000': ['APPLIANCES'],
        'PHILIPS 8000': ['APPLIANCES'],
        'PHILIPS 7000': ['APPLIANCES'],
        'PHILIPS 6000': ['APPLIANCES'],
        'PHILIPS 5000': ['APPLIANCES'],
        'PHILIPS 3000': ['APPLIANCES'],
        'PHILIPS BT 7000': ['APPLIANCES'],
        'PHILIPS BT 5000': ['APPLIANCES'],
        'PHILIPS BT 3000': ['APPLIANCES'],
        'PHILIPS MG 7000': ['APPLIANCES'],
        'PHILIPS MG 5000': ['APPLIANCES'],
        'PHILIPS MG 3000': ['APPLIANCES'],
        'PHILIPS HAIRCLIPPERS': ['APPLIANCES'],
        'PHILIPS ONEBLADE': ['APPLIANCES'],
        'PHILIPS SATINELLE 8000': ['APPLIANCES'],
        'PHILIPS SATINELLE 6000': ['APPLIANCES'],
        'PHILIPS SATINELLE 3000': ['APPLIANCES'],
        'PHILIPS SATINELLE ESSENTIAL': ['APPLIANCES'],
        'PHILIPS LUMEA PRESTIGE': ['APPLIANCES'],
        'PHILIPS LUMEA ADVANCE': ['APPLIANCES'],
        LUPILU: ['DIAPERS', 'WIPES'],
        OTROS: ['DIAPERS'],
        FRENADOL: ['PHARMA'],
        COULDINA: ['PHARMA'],
        PHARMAGRIP: ['PHARMA'],
        SINUS: ['PHARMA'],
        PRANAROM: ['PHARMA'],
        RESPIBIEN: ['PHARMA'],
        'UTABON ': ['PHARMA'],
        RINOBANEDIF: ['PHARMA'],
        DORMIDINA: ['PHARMA'],
        AQUILEA: ['PHARMA'],
        VALERIANA: ['PHARMA'],
        SUPRADYN: ['PHARMA'],
        MULTICENTRUM: ['PHARMA'],
        PHARMATON: ['PHARMA'],
        VITALDE: ['PHARMA'],
        NATALBEN: ['PHARMA', 'PHARMA'],
        REDOXON: ['PHARMA'],
        LEOTRON: ['PHARMA'],
        'SOLGAR ': ['PHARMA'],
        GESTAGYN: ['PHARMA']
      },
      icons: {
        mdiPlus,
        mdiMinus,
        mdiFormatListText
      },
      headers: [
        {
          text: "Brand",
          align: "left",
          sortable: false,
          value: "brand"
        },
        {
          text: "Categories",
          align: "left",
          sortable: false,
          value: "categories"
        },
        {
          text: "Price",
          align: "left",
          sortable: false,
          value: "price"
        },
        {
          text: "RPP",
          align: "left",
          sortable: false,
          value: "rppData"
        },
        {
          text: "Count",
          align: "left",
          sortable: false,
          value: "count"
        },
        {
          text: "Actions",
          value: "action",
          sortable: false
        }
      ],
      otherProducts: []
    };
  },
  props: {
    disabled: Boolean,
  },
  watch: {
    currentReceipt: {
      handler(val) {
        this.otherProducts = val.otherProducts || [];
      },
      deep: true
    }
  },
  methods: {
    addProduct(data, status) {
      const product =
        data && data.item
          ? data.item
          : data
            ? data
            : {
              id: new Date().getMilliseconds().toString(),
              name: "",
              brand: "",
              // categories: [],
              price: "",
              count: 1
            };
      const isFound = this.otherProducts.findIndex(item => item.id === product.id);
      if (isFound >= 0) {
        this.otherProducts[isFound].count++;
      } else {
        this.otherProducts.push({
          ...product,
          count: 1
        });
      }
      this.currentReceipt.otherProducts = this.otherProducts || [];
    },
    searchBrand(item, query) {
      return item.brand.toLowerCase().includes(query.toLowerCase())
    },
    changePrice(event, product) {
      const isFound = this.otherProducts.findIndex(item => item.id === product.id);
      if (isFound >= 0) {
        this.otherProducts[isFound].price = event;
      }
      this.currentReceipt.otherProducts = this.otherProducts;
    },
    changeName(event, product) {
      const isFound = this.otherProducts.findIndex(item => item.id === product.id);
      if (isFound >= 0) {
        this.otherProducts[isFound].name = event;
      }
      this.currentReceipt.otherProducts = this.otherProducts;
    },
    getCategories(product) {
      if (!product.brand) {
        return [];
      }
      if (this.brandCategories[product.brand].length === 1) {
        const isFound = this.otherProducts.findIndex(item => item.id === product.id);
        if (isFound >= 0) {
          this.otherProducts[isFound].categories = this.brandCategories[product.brand].map(item => item);;
        }
        this.currentReceipt.otherProducts = this.otherProducts;
      };
      this.changeName(product.brand, product);
      return (this.brandCategories[product.brand])
    },
    getBrands() {
      return Object.keys(this.brandCategories).sort((a, b) => a.localeCompare(b, 'tr', { sensitivity: 'base' })).map(item => { return { brand: item } });
    },
    removeProduct(data, status) {
      const product = status ? data : data.item;
      const isFound = this.otherProducts.findIndex(item => item.id === product.id);
      if (isFound >= 0) {
        if (this.otherProducts[isFound].count > 1) {
          this.otherProducts[isFound].count--;
        } else {
          this.otherProducts.splice(isFound, 1);
        }
      }
      this.currentReceipt.otherProducts = this.otherProducts;
    },
    searchProduct(item, inpt) {
      return item.brand.toUpperCase().includes(inpt.toUpperCase());
    }
  }
};
</script>
