<template>
  <v-row>
    <v-col cols="6">
      <v-autocomplete
        outlined
        v-model="currentReceipt.retailer"
        :prepend-icon="icons.mdiStore"
        :items="retailers"
        @change="checkOther()"
        item-text="name"
        item-value="id"
        label="Select Retailer"
        auto-select-first
        :required="true"
        :rules="[v => !!v || 'Please select a retailer for the receipt']"
        :disabled="disabled"
        dense
      >
      </v-autocomplete>
    </v-col>
    <v-col cols="6">
      <v-text-field
        v-model="currentReceipt.location"
        v-show="showLocation"
        :prepend-icon="icons.mdiShopping"
        outlined
        label="Location"
        placeholder=""
        hide-details
        class="mb-3"
        :rules="[showLocation ? v => !!v || 'Please enter the location for the receipt' : true]"
        :disabled="disabled"
      >
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import { mdiStore, mdiShopping } from "@mdi/js";
export default {
  methods: {
    checkOther() {
      this.showLocation = this.currentReceipt.retailer === 1 ? true : false;
      if (localStorage.selectedApp !== "PORTUGUESE") {
        axios
          .post("https://la-cuponera-analyzer.justsnap.eu/analyze-receipt", {
            referenceNumber: this.currentReceipt.referenceNumber,
            retailer: this.currentReceipt.retailer,
            campaigns: this.currentReceipt.campaignIds.map(campaign => Number(campaign.id))
          })
          .then(r => {
            this.$store.commit("mutateEnableProducts", true);
            if (r.data.length && !this.currentReceipt.products) {
              this.currentReceipt.products = {};
              r.data.forEach(product => {
                this.currentReceipt.products[product.campaign.toString()] = [
                  {
                    ean: product.barcode,
                    price: product.oPrice,
                    count: 1
                  }
                ];
                this.$emit("foundProducts", r.data);
              });
            }
          });
      } else {
        this.$store.commit("mutateEnableProducts", true);
      }
    }
  },
  data() {
    const retailers = localStorage.retailers ? JSON.parse(localStorage.retailers) : [];
    return {
      retailers,
      showLocation: false,
      location: "",
      icons: {
        mdiStore,
        mdiShopping
      }
    };
  },
  props: {
    required: Boolean,
    disabled: Boolean
  },
  computed: {
    ...mapGetters(["currentReceipt"]),
    ...mapMutations(["mutateEnableProducts"])
  }
};
</script>
