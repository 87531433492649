import _axios from "axios";
import NProgress from "nprogress";

const basicAuth =
  "Basic " + btoa(process.env.VUE_APP_API_KEY + ":" + process.env.VUE_APP_API_SECRET);

const axios = _axios.create({
  baseURL: process.env.VUE_APP_SPAIN_API_URL,
  timeout: 15000,
  headers: {
    Authorization: basicAuth
  }
});

const updateAuthToken = token => {
  axios.defaults.headers["AuthToken"] = `Bearer ${token}`;
};

const updateBaseURL = app => {
  switch (app) {
    case "SPAIN":
      axios.defaults.baseURL = process.env.VUE_APP_SPAIN_API_URL;
      break;
    case "PORTUGUESE":
      axios.defaults.baseURL = process.env.VUE_APP_PORTUGAL_API_URL;
      break;
    default:
      axios.defaults.baseURL = process.env.VUE_APP_SPAIN_API_URL;
  }
};

axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.get["Accepts"] = "application/json";

axios.interceptors.request.use(
  function(request) {
    // Do something before request is sent
    NProgress.start();

    return request;
  },
  function(error) {
    // Do something with request error
    console.log(error);
    NProgress.done();

    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function(response) {
    NProgress.done();

    return response;
  },
  function(error) {
    // Do something with response error
    console.log(error);
    NProgress.done();

    return Promise.reject(error);
  }
);

export { axios, updateAuthToken, updateBaseURL };
