export default {
  snackBar: {
    message: "",
    timeout: 1500,
    type: "error"
  },
  showSnackBar: false,
  isAuthenticated: false,
  userToken: "",
  userRole: "",
  userName: "",
  darkTheme: false,
  autoPilotMode: false,
  currentReceipt: {},
  showDialogue: false,
  prevPartcipations: [],
  processHistory: [],
  paymentHistory: [],
  usedReceiptImageUrl: "",
  usedRereferenceNumber: "",
  deviceInfo: {},
  usersCampaigns: [],
  routes: {
    dashboard: {
      accessRoles: ["ADMIN", "OPERATOR", "TESTER"]
    },
    agent: {
      accessRoles: ["AGENT", "ADMIN", "TESTER", "AGENTL1", "AGENTL3"]
    },
    consumerReceipts: {
      accessRoles: ["ADMIN", "AGENT", "AGENTL1"]
    },
    paymentReceipts: {
      accessRoles: ["ADMIN", "AGENT"]
    },
  },
  enableProducts:false
};
