<template>
  <v-form ref="confirmForm" class="pl-5 pr-10 py-6" style="height: 100%; overflow-x: hidden">
    <v-row justify="space-between" dense>
      <v-col cols="6">
        <v-btn @click="switchTab('receipt')" class="col-sm-12" v-bind:color="showDetail === 'receipt' ? 'primary' : ''"
          outlined>Receipt Details</v-btn>
      </v-col>
      <v-col cols="6" v-if="filterRole(['AGENT', 'ADMIN', 'AGENTL1'])">
        <v-btn @click="switchTab('processHistory')" class="col-sm-12"
          v-bind:color="showDetail === 'processHistory' ? 'primary' : ''" outlined>Process History</v-btn>
      </v-col>
      <v-col cols="6" v-if="filterRole(['AGENT', 'ADMIN', 'AGENTL1'])">
        <v-btn @click="switchTab('oldParticipation')" class="col-sm-12"
          v-bind:color="showDetail === 'oldParticipation' ? 'primary' : ''" outlined>Other Participations</v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn @click="switchTab('campaigns')" class="col-sm-12"
          v-bind:color="showDetail === 'campaigns' ? 'primary' : ''" outlined>Campaign Details</v-btn>
      </v-col>
      <v-col cols="6" v-if="filterRole(['AGENT', 'ADMIN', 'AGENTL1'])">
        <v-btn @click="switchTab('paymentHistory')" class="col-sm-12"
          v-bind:color="showDetail === 'paymentHistory' ? 'primary' : ''" outlined>Payment History</v-btn>
      </v-col>
      <v-col cols="6" v-if="filterRole(['AGENT', 'ADMIN'])">
        <v-btn @click="switchTab('deviceInfo')" class="col-sm-12"
          v-bind:color="showDetail === 'deviceInfo' ? 'primary' : ''" outlined>Device Info</v-btn>
      </v-col>
      <v-col cols="6" v-if="filterRole(['AGENT', 'ADMIN', 'AGENTL1']) && usedReceiptImageUrl">
        <v-btn @click="switchTab('usedReceipt')" class="col-sm-12"
          v-bind:color="showDetail === 'usedReceipt' ? 'primary' : ''" outlined>Used Receipt</v-btn>
      </v-col>
    </v-row>
    <div v-if="showDetail === 'receipt'">
      <v-row justify="space-between" style="padding-top: 20px" dense>
        <v-col cols="12">
          <pick-date :disabled="filterRole(['AGENTL1'])"></pick-date>
        </v-col>
        <v-col cols="6">
          <receipt-id :disabled="filterRole(['AGENTL1'])"></receipt-id>
        </v-col>
        <v-col cols="6">
          <receipt-total :disabled="filterRole(['AGENTL1'])"></receipt-total>
        </v-col>
        <!-- <v-col cols="4">
          <Country :disabled="filterRole(['AGENTL1'])"></Country>
        </v-col> -->
        <v-col cols="12">
          <retailer :disabled="filterRole(['AGENTL1'])" v-on:foundProducts="foundProducts"></retailer>
        </v-col>
        <v-col cols="4" v-if="!filterRole(['AGENTL1'])">
          <check-boxes :disabled="filterRole(['AGENTL1'])"></check-boxes>
        </v-col>
        <v-col cols="4" v-if="!filterRole(['AGENTL1'])">
          <check-boxes-dynamic :disabled="filterRole(['AGENTL1'])" :checkBoxes="[{value: 'fraud', name: 'Report as suspicious receipt'}]"></check-boxes-dynamic>
        </v-col>
      </v-row>
      <v-row justify="space-between">
        <v-col cols="12" v-for="campaign of usersCampaigns" :key="campaign">
          <v-card>
            <v-card-title class="pb-0"> {{ getCampaignName(campaign) }} </v-card-title>
            <v-card-text>
              <participations :campaign="campaign" :disabled="filterRole(['AGENTL1', 'AGENTL3'])" :selected="found">
              </participations>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="!filterRole(['AGENTL1', 'AGENTL3', 'AGENT'])">
          <other-products :disabled="filterRole(['AGENTL1', 'AGENTL3', 'AGENT'])"></other-products>
        </v-col>
        <v-col cols="12">
          <RejectReasonsDropDown v-if="!filterRole([ 'AGENTL3'])"></RejectReasonsDropDown>
          <ReevaluateReasonsDropDown v-if="filterRole(['AGENTL1'])"></ReevaluateReasonsDropDown>
        </v-col>
        <v-col cols="12">
          <handle-receipt :disabled="filterRole(['AGENTL1'])"></handle-receipt>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="showDetail === 'campaigns'">
      <campaign-details :campaignIds="usersCampaigns"></campaign-details>
    </div>
    <div v-else-if="showDetail === 'processHistory'">
      <process-history></process-history>
    </div>
    <div v-else-if="showDetail === 'paymentHistory'">
      <payment-history></payment-history>
    </div>
    <div v-else-if="showDetail === 'deviceInfo'">
      <device-info></device-info>
    </div>
    <div v-else-if="showDetail === 'usedReceipt'">
      <used-receipt-viewer :usedReceiptImageUrl="usedReceiptImageUrl"></used-receipt-viewer>
    </div>
    <div v-else>
      <previous-participations></previous-participations>
    </div>
    <v-row class="justify-end mt-10">
      <v-btn color="warning" @click="close()"> Cancel </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import RejectReasonsDropDown from "./ReceiptDetails/RejectReasonsDropDown";
import ReevaluateReasonsDropDown from "./ReceiptDetails/ReevaluateReasonsDropDown";
import ReceiptId from "./ReceiptDetails/ReceiptId";
// import Country from "./ReceiptDetails/Country";
import CheckBoxes from "./ReceiptDetails/CheckBoxes.vue"
import CheckBoxesDynamic from './ReceiptDetails/CheckBoxesDynamic.vue'
import PickDate from "./ReceiptDetails/PickDate";
import ReceiptTotal from "./ReceiptDetails/ReceiptTotal";
import Retailer from "./ReceiptDetails/Retailer";
import HandleReceipt from "./ReceiptDetails/HandleReceipt";
import PreviousParticipations from "./ReceiptDetails/PreviousParticipations";
import ProcessHistory from "./ReceiptDetails/ProcessHistory";
import PaymentHistory from "./ReceiptDetails/PaymentHistory";
import DeviceInfo from "./ReceiptDetails/DeviceInfo";
import UsedReceiptViewer from "./ReceiptDetails/UsedReceiptViewer";
import Participations from "./ReceiptDetails/Participations";
import CampaignDetails from "./CampaignDetails";
import OtherProducts from "./ReceiptDetails/OtherProducts";

export default {
  data() {
    const campaigns = localStorage.campaigns ? JSON.parse(localStorage.campaigns) : [];
    const user = JSON.parse(localStorage.getItem("user"));
    const userRole = user.role;
    return {
      campaigns,
      showDetail: "receipt",
      tab: null,
      userRole,
      found: null,
    };
  },
  components: {
    PickDate,
    RejectReasonsDropDown,
    ReevaluateReasonsDropDown,
    ReceiptId,
    // Country,
    CheckBoxes,
    CheckBoxesDynamic,
    ReceiptTotal,
    Retailer,
    HandleReceipt,
    UsedReceiptViewer,
    ProcessHistory,
    PreviousParticipations,
    Participations,
    CampaignDetails,
    OtherProducts,
    PaymentHistory,
    DeviceInfo
  },
  computed: {
    ...mapGetters(["currentReceipt", "usersCampaigns", "usedReceiptImageUrl"]),
  },
  methods: {
    ...mapActions(["getDashboardData", "snackAlert"]),
    ...mapActions(["dropReceipt"]),
    filterRole(roles) {
      return roles.includes(this.userRole)
    },
    productsFoundFunction(v) {
      this.disableProducts = v
    },
    foundProducts(v) {
      this.found = v
    },
    getCampaignName(campaignId) {
      const campaign = this.campaigns.find(campaign => campaign.id == campaignId)
      return campaign.dashboardTitle ? `${campaignId} - ${campaign.dashboardTitle}` : `${campaignId} - ${campaign.title}`
    },

    switchTab(status) {
      this.showDetail = status;
    },
    close() {
      this.dropReceipt(this.currentReceipt.receiptToken);
      this.$store.commit("setCurrentReceipt", {});
      this.$store.commit("setPaymentHistory", []);
      this.$store.commit("setUsersCampaigns", []);
      this.$store.commit("setDeviceInfo", {});
      this.$store.commit("setPrevPartcipations", []);
      this.$store.commit("setUsedReceiptImageUrl", '');
      this.$store.commit("setShowDialogue", false);
      this.$store.commit('mutateEnableProducts', false)
    }
  }
};
</script>

<style></style>
