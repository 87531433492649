<template>
  <v-row>
    <v-col cols="8">
      <date-picker :dateRule="true" label="Receipt Date" :icon="icons.mdiCalendarQuestion"
        :value="receiptDateTime.formattedDate" @input="receiptDateTime = { type: 'date', value: $event }" min="2022-04-01"
        format="DD/MM/YYYY" :max="getToday()" :required="true" writable
        :rules="[v => !!v || 'Please enter the receipt date']" :disabled="disabled" dense />
    </v-col>
    <v-col cols="4">
      <v-text-field outlined :value="receiptDateTime.formattedTime" :prepend-icon="icons.mdiClockOutline"
        @input="receiptDateTime = { type: 'time', value: $event }" label="Receipt Time" format="24hr" type="time"
        :required="true" :rules="[v => !!v || 'Please enter the receipt time']" :disabled="disabled" dense></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import DatePicker from "@/components/Dialogue/DatePicker";
import { mdiCalendarQuestion, mdiClockOutline } from "@mdi/js";

export default {
  data() {
    return {
      icons: {
        mdiCalendarQuestion,
        mdiClockOutline
      }
    };
  },
  watch: {
    receiptDateTime: {
      handler(val) {
        if (this.receiptDateTime.formattedDate) {
          const campaignIds = this.currentReceipt.campaignIds.map(i => parseInt(i.id))
          const campaigns = localStorage.campaigns ? JSON.parse(localStorage.campaigns) : [];
          const currentCampaigns = campaigns.filter(item => campaignIds.includes(item.id));
          let wrongDate = 0;
          for (const campaign of currentCampaigns) {
            const startDate = moment(campaign.startDate).format("YYYY-MM-DD 00:00")
            const endDate = moment(campaign.endDate).format("YYYY-MM-DD 23:59")
            const selectedReceiptDateTime = moment(this.receiptDateTime.formattedDate).format("YYYY-MM-DD HH:mm")
            const result = moment(selectedReceiptDateTime).isBetween(startDate, endDate, null, '[]')
            if (!result) {
              wrongDate++
            }
          }

          if (wrongDate > 0 && currentCampaigns.length === wrongDate) {
            this.snackAlert({
              message: `Receipt date is not in the campaign date range`
            });
          }
        }
      },
    }
  },
  methods: {
    ...mapActions(["snackAlert"]),
    getToday() {
      return moment().format("YYYY-MM-DD");
    },
  },
  components: {
    DatePicker
  },
  props: {
    disabled: Boolean
  },
  computed: {
    ...mapGetters(["currentReceipt"]),
    receiptDateTime: {
      get() {
        const receiptDateTime = this.currentReceipt.receiptDateTime;
        if (!receiptDateTime)
          return {
            formattedDate: null,
            formattedTime: null
          };
        const formattedTime = moment.parseZone(receiptDateTime).format("HH:mm");
        return {
          formattedDate: receiptDateTime,
          formattedTime
        };
      },
      set(val) {
        const { formattedDate: receiptDateTime, formattedTime } = this.receiptDateTime;
        const formattedDate = moment.parseZone(receiptDateTime).format("YYYY-MM-DD");
        const { type, value } = val;
        let newDate = null;
        if (value) {
          if (type === "date") {
            newDate = moment.parseZone(`${value} ${formattedTime || "00:00"}`).format("YYYY-MM-DD HH:mm");
          } else if (type === "time") {
            newDate = moment.parseZone(`${formattedDate} ${value}`).format("YYYY-MM-DD HH:mm");
          }
          this.currentReceipt.receiptDateTime = newDate;
        }
      }
    }
  }
};
</script>
