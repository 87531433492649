<template>
  <v-row>
    <v-row>
      <v-col cols="12" md="12">
        <search-payment-ref></search-payment-ref>
      </v-col>
    </v-row>
    <receipt-dialogue :showDialogue="getShowDialogue" @close="showDialogue = false" />
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

import ReceiptDialogue from "./ReceiptDialogue.vue";
import SearchPaymentRef from "@/components/SearchPaymentRef.vue";


export default {
  computed: {
    ...mapGetters(["showDialogue"]),
    getShowDialogue: {
      get() {
        return this.showDialogue;
      }
    }
  },
  components: {
    ReceiptDialogue,
    SearchPaymentRef
  }
}
</script>
