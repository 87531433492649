import "@/styles/styles.scss";
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import VueCountdown from "@chenfengyuan/vue-countdown";
import Viewer from 'v-viewer'

Vue.use(Viewer)
Vue.component(VueCountdown.name, VueCountdown);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
