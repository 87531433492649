<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <div class="image_block">
        <img :src="require(`@/assets/images/misc/js-logo.png`)" alt="Justsnap" />
      </div>
      <v-card class="auth-card">
        <v-card-text>
          <v-form ref="form" v-model="valid" @keyup.native.enter="loginUser">
            <v-text-field
              v-model="userName"
              outlined
              label="Username"
              placeholder="username"
              hide-details
              class="mb-3"
              :rules="[v => v.length >= 3]"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
              :rules="[
                v => !!v || 'Login.passwordRequired',
                v => v.length >= 6 || 'Login.passwordMoreThen',
                v => v.length <= 16 || 'Login.passwordLessThen'
              ]"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox v-model="rememberMe" label="Remember Me" hide-details class="me-3 mt-1">
              </v-checkbox>

              <!-- forgot link -->
              <a href="/forgotPassword" class="mt-1"> Forgot Password? </a>
            </div>

            <v-btn block color="primary" class="mt-6" @click="loginUser"> Login </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js";
import { ref } from "vue";
import { mapActions } from "vuex";
import { updateBaseURL } from "@/plugins/axios";

export default {
  methods: {
    ...mapActions(["login", "snackAlert"]),

    loginUser() {
      if (this.$refs.form.validate()) {
        let user = {
          email: this.userEmail,
          password: this.userPassword
        };
        const dashboard = this.selectedDashboard;
        if (this.rememberMe) {
          localStorage.setItem(
            "loginUser",
            JSON.stringify({
              userName: this.userName,
              password: this.password,
              rememberMe: this.rememberMe
            })
          );
        } else {
          localStorage.setItem(
            "loginUser",
            JSON.stringify({
              userName: "",
              password: "",
              rememberMe: false
            })
          );
        }
        this.login({
          user: {
            userName: this.userName,
            password: this.password
          }
        }).then(result => {
          updateBaseURL(result.app);
          switch (result.app) {
            case "SPAIN":
            case "PORTUGUESE":
              if (["ADMIN", "OPERATOR", "TESTER"].includes(result.role)) {
                this.$router.push({
                  name: "dashboard"
                });
              } else if (["AGENT", "AGENTL1", "AGENTL3"].includes(result.role)) {
                this.$router.push({
                  name: "agent"
                });
              } else {
                this.snackAlert({
                  message: "You are not authorized to access this page",
                  type: "error"
                });
              }
              break;
            case "ALL":
              this.$router.push({
                name: "selectBoard"
              });
              break;
            default:
              this.snackAlert({
                message: "You are not authorized to access this page",
                type: "error"
              });
          }
        });
      }
    }
  },
  setup() {
    const isPasswordVisible = ref(false);
    const loginUser =
      localStorage && localStorage.getItem("loginUser")
        ? JSON.parse(localStorage.getItem("loginUser"))
        : { userName: "", password: "", rememberMe: false };
    const userName = loginUser.userName;
    const password = loginUser.password;
    const rememberMe = loginUser.rememberMe;
    return {
      isPasswordVisible,
      rememberMe,
      userName,
      password,
      valid: true,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline
      }
    };
  }
};
</script>

<style lang="scss">
@import "~@/plugins/vuetify/default-preset/preset/pages/auth.scss";
</style>
