<template>
  <div>
    <v-card>
      <v-card-title class="primary"
        ><span class="white--text">Search Payment Reference Number</span></v-card-title
      >
      <v-card-actions>
        <v-text-field
          clearable
          v-model="paymentRef"
          label="Payment Reference Number"
          :prepend-icon="icons.mdiAccount"
          type="text"
          color="black"
          @keypress.enter="searchRef"
        ></v-text-field>
      </v-card-actions>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn @click="searchRef" color="primary" large>Search</v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="mt-4" v-if="receipts.length > 0">
      <v-simple-table dense>
        <thead>
          <tr>
            <th>ID</th>
            <th>Consumer ID</th>
            <th>Receipt Reference Number</th>
            <th>Transfer Request Date</th>
            <th>Payment Date</th>
            <th>Receipt Created At</th>
            <th>Payment Provider</th>
            <th>Recipient Full Name</th>
            <th>Recipient IBAN</th>
            <th>Transaction Payment Amount</th>
            <th>Receipt Payment Amount</th>
          </tr>
        </thead>
        <tbody v-for="item of receipts" :key="item.receiptReferenceNumber">
          <tr class="cursor" @click="getReceipt(item.receiptReferenceNumber)">
            <td>{{ item.id }}</td>
            <td>{{ item.consumerId }}</td>
            <td>{{ item.receiptReferenceNumber }}</td>
            <td>{{ getDateTime(item.transferRequestDate) }}</td>
            <td>{{ getDateTime(item.paymentDate) }}</td>
            <td>{{ getDateTime(item.receiptCreatedAt) }}</td>
            <td>{{ item.paymentProvider }}</td>
            <td>{{ item.recipientSecretFullName }}</td>
            <td>{{ item.secretIban }}</td>
            <td>{{ item.transactionPaymentAmount }}</td>
            <td>{{ item.receiptPaymentAmount }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import { mdiAccount } from "@mdi/js";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      receipts: [],
      paymentRef: "",
      icons: {
        mdiAccount,
      },
    };
  },
  methods: {
    ...mapActions(["getReceiptsWithPaymentRef", "snackAlert", "getReceiptWithReferenceNumber"]),
    searchRef () {
      if (this.paymentRef) {
        this.getReceiptsWithPaymentRef(this.paymentRef).then(res => {
          this.receipts = res.result;
        }).catch(err => {
          this.snackAlert({
            message: `No receipts found`,
          });
        })
      } else {
        this.snackAlert({
          message: `Wrong Reference Number`,
        });
      }
    },
    getDateTime(date) {
      if (moment(date).isValid())
        return moment.parseZone(date).format("MMMM Do YYYY, HH:mm:ss");
      return "";
    },
    getReceipt(referenceNumber) {
      this.getReceiptWithReferenceNumber(referenceNumber).then(hasReceipt => {
        if (hasReceipt) this.$store.commit("setShowDialogue", true);
      });
    },
  },
};
</script>
