<template>
  <div class="select-board-wrapper select-board-v1">
    <h3>
      Please select which app do you want to choose on working
    </h3>
    <div class="select-board-inner">
      <v-card @click="selectDashboard('PORTUGUESE')" class="select-board-card">
        <img
          class="app-logo"
          height="120"
          :src="require(`@/assets/images/app_logos/portuguese.png`)"
        />
        <p>Poupar é Ganhar</p>
      </v-card>
      <v-card @click="selectDashboard('SPAIN')" class="select-board-card">
        <img class="app-logo" height="120" :src="require(`@/assets/images/app_logos/spain.png`)" />
        <p>La Cuponera</p>
      </v-card>
    </div>
    <!-- background triangle shape  -->
    <!-- background triangle shape  -->
    <img
      class="select-board-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img
      class="select-board-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="select-board-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js";
import { ref } from "vue";
import { mapActions, mapGetters } from "vuex";
import { updateBaseURL } from "@/plugins/axios";

export default {
  computed: {
    ...mapGetters(["userRole"])
  },
  methods: {
    ...mapActions(["login", "snackAlert"]),
    selectDashboard(app) {
      updateBaseURL(app);
      localStorage.selectedApp = app;
      if (["ADMIN", "OPERATOR", "TESTER"].includes(this.userRole)) {
        this.$router.push({
          name: "dashboard"
        });
      } else if (["AGENT", "AGENTL1", "AGENTL3"].includes(this.userRole)) {
        this.$router.push({
          name: "agent"
        });
      } else {
        this.snackAlert({
          message: "You are not authorized to access this page",
          type: "error"
        });
      }
    },
    loginUser() {
      if (this.$refs.form.validate()) {
        let user = {
          email: this.userEmail,
          password: this.userPassword
        };
        const dashboard = this.selectedDashboard;
        if (this.rememberMe) {
          localStorage.setItem(
            "loginUser",
            JSON.stringify({
              userName: this.userName,
              password: this.password,
              rememberMe: this.rememberMe
            })
          );
        } else {
          localStorage.setItem(
            "loginUser",
            JSON.stringify({
              userName: "",
              password: "",
              rememberMe: false
            })
          );
        }
        this.login({
          user: {
            userName: this.userName,
            password: this.password
          }
        }).then(result => {
          switch (result.app) {
            case "SPAIN":
            case "PORTUGUESE":
              if (["ADMIN", "OPERATOR", "TESTER"].includes(result.role)) {
                this.$router.push({
                  name: "dashboard"
                });
              } else if (["AGENT", "AGENTL1", "AGENTL3"].includes(result.role)) {
                this.$router.push({
                  name: "agent"
                });
              } else {
                this.snackAlert({
                  message: "You are not authorized to access this page",
                  type: "error"
                });
              }
              break;
            case "ALL":
              this.$router.push({
                name: "selectBoard"
              });
              break;
            default:
              this.snackAlert({
                message: "You are not authorized to access this page",
                type: "error"
              });
          }
        });
      }
    }
  },
  setup() {
    const isPasswordVisible = ref(false);
    const loginUser =
      localStorage && localStorage.getItem("loginUser")
        ? JSON.parse(localStorage.getItem("loginUser"))
        : { userName: "", password: "", rememberMe: false };
    const userName = loginUser.userName;
    const password = loginUser.password;
    const rememberMe = loginUser.rememberMe;
    return {
      isPasswordVisible,
      rememberMe,
      userName,
      password,
      valid: true,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline
      }
    };
  }
};
</script>

<style lang="scss">
@import "~@/plugins/vuetify/default-preset/preset/pages/select-board.scss";
</style>
