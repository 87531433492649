<template>
  <div>
    <v-checkbox v-model="currentReceipt.onlyPgProducts" label="P&G only 4+" :disabled="disabled"></v-checkbox>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
    };
  },
  props: {
    disabled: Boolean
  },
  computed: {
    ...mapGetters(["currentReceipt"])
  }
};
</script>
