import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import Login from "@/views/pages/Login.vue";
import ForgotPassword from "@/views/pages/ForgotPassword.vue";
import ResetPassword from "@/views/pages/ResetPassword.vue";
import Dashboard from "@/views/dashboard/Dashboard.vue";
import SelectBoard from "@/views/dashboard/SelectBoard.vue";
import ConsumerReceipts from "@/views/dashboard/ConsumerReceipts";
import PaymentReceipts from "@/views/dashboard/PaymentReceipts";
import Agent from "@/views/dashboard/Agent.vue";
import Error404 from "@/views/Error.vue";
import Profile from "@/views/pages/account-settings/AccountSettings.vue";
import { updateAuthToken, updateBaseURL } from "../plugins/axios";

Vue.use(VueRouter);

const isAuthenticated = (to, from, next) => {
  const routeConfig = store.getters.routes[to.name];
  if (store.getters.isAuthenticated || localStorage.isAuthenticated) {
    store.commit("setIsAuthenticated", true);
    if (localStorage.user) {
      const user = JSON.parse(localStorage.user);
      store.commit("setUserName", user.userName);
      store.commit("setUserRole", user.role);
      store.commit("setUserApp", user.app);
      store.commit("setUserToken", user.userToken);
      updateAuthToken(user.userToken);
      if (localStorage.selectedApp) {
        updateBaseURL(localStorage.selectedApp);
      } else if (user.app !== "ALL") {
        updateBaseURL(user.app);
      }
    }
    if (routeConfig && routeConfig.accessRoles) {
      const userRole = store.getters.userRole;
      if (routeConfig.accessRoles.includes(userRole)) {
        next();
      } else {
        next({ name: "error-404" });
      }
    }
    next();
    return;
  }
  next("/login");
};

const routes = [
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: ForgotPassword,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    component: ResetPassword,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/",
    redirect: "dashboard",
    beforeEnter: isAuthenticated
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    beforeEnter: isAuthenticated
  },
  {
    path: "/consumerReceipts",
    name: "consumerReceipts",
    component: ConsumerReceipts,
    beforeEnter: isAuthenticated
  },
  {
    path: "/paymentReceipts",
    name: "paymentReceipts",
    component: PaymentReceipts,
    beforeEnter: isAuthenticated
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    beforeEnter: isAuthenticated
  },
  {
    path: "/agent",
    name: "agent",
    component: Agent,
    beforeEnter: isAuthenticated
  },
  {
    path: "/selectBoard",
    name: "selectBoard",
    component: SelectBoard,
    beforeEnter: isAuthenticated,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/error-404",
    name: "error-404",
    component: Error404,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "*",
    redirect: "error-404"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
