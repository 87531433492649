<template>
  <v-text-field
    required
    outlined
    v-model="currentReceipt.receiptId"
    label="Receipt ID"
    :prepend-icon="icons.mdiReceipt"
    :rules="[v => !!v || 'Please enter the receipt id', v => v != 0 || 'Receipt Id cannot be 0']"
    :disabled="disabled"
    dense
  ></v-text-field>
</template>

<script>
import { mapGetters } from "vuex";
import { mdiReceipt } from "@mdi/js";

export default {
  data() {
    return {
      icons: {
        mdiReceipt
      }
    };
  },
  props: {
    disabled: Boolean
  },
  computed: {
    ...mapGetters(["currentReceipt"])
  }
};
</script>
