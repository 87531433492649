<template>
  <div>
    <template v-for="(checkBox, index) in checkBoxes" :key="">
      <v-checkbox v-model="currentReceipt[checkBox.value]" :label="checkBox.name" :disabled="disabled"></v-checkbox>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
    };
  },
  props: {
    disabled: Boolean,
    checkBoxes: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(["currentReceipt"])
  }
};
</script>