<template>
  <v-row>
    <v-col>
      <v-col cols="12" md="12">
        <auto-pilot @apIntervalEnd="apIntervalEnd"></auto-pilot>
      </v-col>
      <v-col cols="12" md="12">
        <search-reference-number v-if="userRole !== 'OPERATOR'"></search-reference-number>
      </v-col>
    </v-col>
    <v-col cols="12" md="4">
      <dashboard-statistics-card></dashboard-statistics-card>
    </v-col>
    <receipt-dialogue :showDialogue="getShowDialogue" @close="showDialogue = false" />
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

// demos
import AutoPilot from "./AutoPilot.vue";
import DashboardStatisticsCard from "./DashboardStatisticsCard.vue";
import ReceiptDialogue from "./ReceiptDialogue.vue";
import SearchReferenceNumber from "@/components/SearchReferenceNumber.vue";

export default {
  computed: {
    ...mapGetters(["showDialogue", "userRole"]),
    getShowDialogue: {
      get() {
        return this.showDialogue;
      },
    },
  },
  created() {
    this.getDashboardData();
  },
  components: {
    AutoPilot,
    DashboardStatisticsCard,
    ReceiptDialogue,
    SearchReferenceNumber,
  },

  data() {
    return {};
  },
  methods: {
    ...mapActions(["getOneReceipt", "getDashboardData"]),
    apIntervalEnd() {
      if (this.showDialogue) return;
      this.getOneReceipt().then((hasReceipt) => {
        if (hasReceipt) this.$store.commit("setShowDialogue", true);
      });
    },
  },
};
</script>
