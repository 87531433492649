<template>
  <v-app>
    <v-snackbar v-model="showSnack" :color="snackBar.type" :timeout="snackBar.timeout" bottom>{{
      snackBar.message
    }}</v-snackbar>
    <component :is="resolveLayout">
      <router-view></router-view>
    </component>
  </v-app>
</template>

<script>
import { computed } from "vue";
import { useRouter } from "@/utils";
import LayoutBlank from "@/layouts/Blank.vue";
import LayoutContent from "@/layouts/Content.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  methods: {
    ...mapActions(["snackAlert", "setTheme"])
  },
  computed: {
    ...mapGetters(["snackBar", "showSnackBar", "darkTheme"]),
    showSnack: {
      get() {
        return this.showSnackBar;
      },
      set(val) {
        this.$store.commit("setSnackBarShow", val);
      }
    }
  },
  components: {
    LayoutBlank,
    LayoutContent
  },
  created() {
    this.$vuetify.theme.dark = localStorage.darkTheme === "true" ? true : false;
    if (localStorage && localStorage.darkTheme) this.setTheme(localStorage.darkTheme === "true");
  },
  setup() {
    const { route } = useRouter();

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null;

      if (route.value.meta.layout === "blank") return "layout-blank";

      return "layout-content";
    });

    return {
      resolveLayout
    };
  },
  watch: {
    darkTheme(val) {
      this.$vuetify.theme.dark = !val;
      localStorage.darkTheme = !val;
    }
  }
};
</script>
