<template>
  <div v-if="currentReceipt.receiptImageURL" class="viewer image-container">
    <v-container>
      <v-flex>

      </v-flex>
      <v-row class="topButtons">
        <v-col :cols="!currentReceipt.productImageURLs ? '12' : '6'" class="m-0">
          <v-btn tile depressed width="100%" :color="showReceiptImage ? 'secondary' : ''"
            @click="setShowReceiptImage(true, '')">
            Receipt
          </v-btn>
        </v-col>
        <v-col v-if="currentReceipt.productImageURLs" cols="6">
          <v-btn width="100%" tile depressed :color="!showReceiptImage ? 'secondary' : ''"
            @click="setShowReceiptImage(false)">
            Product
          </v-btn>
        </v-col>

      </v-row>
      <v-row>
        <v-flex md12 class="image-container">
          <div v-if="showReceiptImage" class="images"
            v-viewer="{ inline: true, button: true, fullscreen: false, navbar: false }">
            <img style="width: 100%;" crossorigin='anonymous' :src="currentReceipt.receiptImageURL" alt="Receipt image">
          </div>
          <template v-else-if="showProductImage">
            <v-carousel :show-arrows="true" height="100%">
              <v-carousel-item v-for="(value, key) in currentReceipt.productImageURLs" :key="key">
                <h2 class="text-center my-3">{{ getCampaignName(key) }}</h2>
                <img width="100%" crossorigin='anonymous' :src="value"/>
              </v-carousel-item>
            </v-carousel>
          </template>
        </v-flex>

      </v-row>

    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import 'viewerjs/dist/viewer.css'
export default {
  data: () => {
    const campaigns = localStorage.campaigns ? JSON.parse(localStorage.campaigns) : [];
    return {
      campaigns,
      showReceiptImage: true,
      showProductImage: false,
    }
  },
  components: {
  },
  props: {
  },
  methods: {
    inited(viewer) {
      this.$viewer = viewer
    },
    show() {
      this.$viewer.show()
    },
    getCampaignName(campaignId) {
      const campaign = this.campaigns.find(campaign => campaign.id == campaignId)
      return campaign.dashboardTitle ? `${campaignId} - ${campaign.dashboardTitle}` : `${campaignId} - ${campaign.title}`
    },
    setShowReceiptImage(status) {
      this.showReceiptImage = status;
      this.showProductImage = !status;
    },
  },
  computed: {
    ...mapGetters(["currentReceipt"]),
    imgBtnColor() {
      return this.$vuetify.theme.isDark ? {
        selected: 'grey darken-4',
        unselected: 'grey darken-3'
      } : {
        selected: 'grey lighten-1',
        unselected: 'grey lighten-3'
      };
    },
  }
};
</script>
<style lang="scss" scoped>
.image-container {
  overflow: hidden;
  height: 100%;
}

.topButtons {
  z-index: 12;
  padding: 0 !important;

  .col-6,
  .col-12 {
    padding: 0 !important;
  }
}
</style>
