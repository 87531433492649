<template>
  <v-dialog
    v-bind:value="showDialogue"
    transition="dialog-bottom-transition"
    @click:outside="hideViewer()"
    @keydown.esc="hideViewer()"
    min-width="600px"
    content-class="overflow-hidden"
    class="p-5"
  >
    <v-main style="max-height: 88vh; padding: 0 !important" class="pa-0">
      <v-container
        fluid
        class="pa-0 d-flex flex-column flex-grow-1 fill-parent-height"
        :style="darkTheme === 'true' ? 'background-color: #2C2843' : 'background-color: #e9e1f0'"
      >
        <v-row class="pa-5 title">
          <v-chip :color="getColor" class="rounded-pill">
            {{ receiptStatus(currentReceipt) }}
          </v-chip>
          <div class="pl-4">
            Reference:
            <span class="clipboard" @click="copy(currentReceipt.referenceNumber)">{{
              currentReceipt.referenceNumber
            }}</span>
          </div>

          <div class="pl-4">
            <v-chip :color="getFraudColor" class="rounded-pill">
              Fraud Suspicion Level: {{ currentReceipt.fraudSuspicionLevel }}
          </v-chip>
          </div>

          <!-- Agent Only Header -->
          <div v-if="userRole === 'AGENT'" class="msgcont">
            <v-chip :color="currentReceipt.sendSftp === 1 ? 'blue' : 'grey'" class="msg">
              {{ currentReceipt.sendSftp === 1 ? "Stfp Sent" : "Stfp Unsent" }}
            </v-chip>
          </div>
        </v-row>
        <v-row no-gutters class="top-row flex-grow-1 flex-shrink-1 ma-0">
          <v-col cols="6" class="fill-parent-height">
            <receipt-viewer />
          </v-col>
          <v-col cols="6" class="fill-parent-height">
            <receipt-details @close="hideViewer()" />
          </v-col>
        </v-row>
        <v-row>
          <v-dialog
            persistent
            @click:outside="hideViewer()"
            @keydown.esc="hideViewer()"
            min-width="400px"
            content-class="overflow-hidden"
            class="p-5"
          >
          </v-dialog>
        </v-row>
      </v-container>
    </v-main>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ReceiptViewer from "@/components/Dialogue/ReceiptViewer.vue";
import ReceiptDetails from "@/components/Dialogue/ReceiptDetails.vue";
export default {
  components: {
    ReceiptViewer,
    ReceiptDetails
  },
  props: {
    showDialogue: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      darkTheme: localStorage.darkTheme
    };
  },
  computed: {
    ...mapGetters(["rejectReasons", "currentReceipt"]),
    ...mapGetters(["userRole"]),

    getColor() {
      const status = this.currentReceipt.status;
      if (status === "PROCESSED") return "success";
      else if (status === "REJECTED") return "error";
      else if (status === "PRE-CONFIRMED") return "secondary";
      else if (status === "RE-EVALUATION") return "info";
      else return "blue-grey";
    },
    getFraudColor() {
      const fraudSuspicionLevel = this.currentReceipt.fraudSuspicionLevel;
      if (fraudSuspicionLevel >= 3) return "error";
      else if (fraudSuspicionLevel >= 1) return "warning";
      else if (fraudSuspicionLevel < 1) return "success";
      else return "blue-grey";
    }
  },
  methods: {
    ...mapActions(["dropReceipt", "snackAlert"]),
    hideViewer() {
      this.dropReceipt(this.currentReceipt.receiptToken);
      this.$store.commit("setCurrentReceipt", {});
      this.$store.commit("setUsersCampaigns", []);
      this.$store.commit("setPaymentHistory", []);
      this.$store.commit("setDeviceInfo", {});
      this.$store.commit("setPrevPartcipations", []);
      this.$store.commit("setShowDialogue", false);
      this.$store.commit('mutateEnableProducts',false)

    },
    copy(text) {
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(text);

      /* Alert the copied text */
      this.snackAlert({
        message: "Reference number copied to clipboard",
        type: "success"
      });
    },
    receiptStatus(receipt) {
      if (receipt.status == 'REJECTED') {
        return `REJECTED - ${receipt.rejectReason}`
      } else if (receipt.status == 'RE-EVALUATION') {
        return `RE-EVALUATED - ${receipt.reclaimReason}`
      } else if (receipt.status == 'PROCESSED') {
        return `PROCESSED - ${receipt.paymentAmount}`
      } else {
        return receipt.status
      }
    }
  }
};
</script>

<style lang="scss">
.clipboard {
  cursor: pointer;
}
.msgcont {
  margin-left: 2rem;

  .msg + .msg {
    margin-left: 0.5rem;
  }
}

.fill-parent-height {
  height: 100%;
}

.top-row {
  min-height: 0;
}

.topButtons {
  z-index: 12;
}
</style>
