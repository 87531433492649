<template>
  <v-card v-if="!['AGENTL1'].includes(userRole)">
    <v-card-title class="align-start">
      <span>Statistics</span>
      <v-spacer></v-spacer>
      <v-btn icon small class="me-n3 mt-n2" @click="getStatistic">
        <v-icon>
          {{ icons.mdiRefresh }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <div v-if="['ADMIN', 'OPERATOR', 'TESTER'].includes(userRole)">
      <v-card-text>
        <v-list class="pb-0">
          <v-list-item>
            <div class="d-flex align-center flex-grow-1 flex-wrap">
              <div class="me-2">
                <div class="font-weight-semibold">
                  <span class="text--primary text-base me-1 success">{{
                    pendingInPreConfirm
                  }}</span>
                  <span class="text--primary text-base me-1"
                    >Pre-Confirm receipts in the queue.</span
                  >
                </div>
              </div>
              <v-spacer></v-spacer>
            </div>
          </v-list-item>
        </v-list>
      </v-card-text>
    </div>
    <div v-if="['AGENT', 'ADMIN'].includes(userRole)">
      <v-card-text>
        <v-list class="pb-0">
          <v-list-item>
            <div class="d-flex align-center flex-grow-1 flex-wrap">
              <div class="me-2">
                <div class="font-weight-semibold">
                  <span class="text--primary text-base me-1 success">{{
                    pendingInReEvaluation
                  }}</span>
                  <span class="text--primary text-base me-1"
                    >Re-Evaluation receipts in the queue.</span
                  >
                </div>
              </div>
              <v-spacer></v-spacer>
            </div>
          </v-list-item>
        </v-list>
      </v-card-text>
    </div>
      <div v-if="!['AGENT'].includes(userRole)">
        <v-card-title class="align-start">
          <span>Daily Performance</span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-list class="pb-0">
            <v-list-item
              v-for="(data, index) in dailyPerformance"
              :key="data.day"
              :class="`d-flex align-center px-0 ${index > 0 ? 'mt-4' : ''}`"
            >
              <v-avatar
                color="primary"
                size="45"
                :class="`primary white--text font-weight-medium me-3`"
              >
                <span class="text-base">{{ getDay(data.day) }}</span>
              </v-avatar>

              <div class="d-flex align-center flex-grow-1 flex-wrap">
                <div class="me-2">
                  <div class="font-weight-semibold">
                    <span class="text--primary text-base me-1">{{ data.day }}</span>
                  </div>

                  <v-list-item-subtitle class="text-xs">
                    <span :class="`text-xs success--text`">Process: {{ data.PROCESSED || 0 }}</span>
                    <span :class="`text-xs error--text`"> Reject: {{ data.REJECTED || 0 }}</span>
                  </v-list-item-subtitle>
                </div>

                <v-spacer></v-spacer>

                <div>
                  <h4 class="font-weight-semibold">
                    {{ getSum(data) }}
                  </h4>
                  <span class="text-xs">Receipt(s)</span>
                </div>
              </div>
            </v-list-item>
          </v-list>
        </v-card-text>
      </div>
  </v-card>
</template>

<script>
import { mdiRefresh } from "@mdi/js";
import { mapActions } from "vuex";
import moment from "moment";
export default {
  data() {
    const user = JSON.parse(localStorage.getItem("user"));
    const userRole = user.role;
    return {
      userRole,
      dailyPerformance: [],
      months: [],
      total: [],
      pendingInPreConfirm: 0,
      pendingInReEvaluation: 0,
      icons: {
        mdiRefresh,
      },
    };
  },
  methods: {
    ...mapActions(["dashboarderStatistics"]),
    getStatistic() {
      this.dashboarderStatistics({}).then((res) => {
        this.dailyPerformance = res.dailyPerformance;
        this.pendingInPreConfirm = res.pendingInPreConfirm;
        this.pendingInReEvaluation = res.pendingInReEvaluation;
      });
    },
    getDay(day) {
      return moment(day).format("DD/MM");
    },
    getSum(data) {
      const confirm = data.PROCESSED || 0;
      const reject = data.REJECTED || 0;
      return confirm + reject;
    },
  },
  mounted() {
    this.getStatistic();
  },
};
</script>
