import vue from "vue";

export default {
  snackBar: (state, payload) => {
    state.snackBar = payload;
    state.showSnackBar = true;
  },
  setSnackBarShow: (state, value) => {
    state.showSnackBar = value;
  },
  setIsAuthenticated: (state, value) => {
    state.isAuthenticated = value;
  },
  setUserToken: (state, value) => {
    state.userToken = value;
  },
  setUserRole: (state, value) => {
    state.userRole = value;
  },
  setUserApp: (state, value) => {
    state.userApp = value;
  },
  setUserName: (state, value) => {
    state.userName = value;
  },
  setDarkTheme: (state, value) => {
    state.darkTheme = value;
  },
  setAutoPilotMode: (state, value) => {
    state.autoPilotMode = value;
  },
  setCurrentReceipt: (state, value) => {
    state.currentReceipt = value;
  },
  setShowDialogue: (state, value) => {
    state.showDialogue = value;
  },
  setPrevPartcipations: (state, value) => {
    state.prevPartcipations = value;
  },
  setUsedReceiptImageUrl: (state, value) => {
    state.usedReceiptImageUrl = value;
  },
  setUsedRereferenceNumber: (state, value) => {
    state.usedRereferenceNumber = value;
  },
  setProcessHistory: (state, value) => {
    state.processHistory = value;
  },
  setPaymentHistory: (state, value) => {
    state.paymentHistory = value;
  },
  setUsersCampaigns: (state, value) => {
    state.usersCampaigns = value;
  },
  setDeviceInfo: (state, value) => {
    state.deviceInfo = value;
  },
  mutateEnableProducts: (state, value) => {
    state.enableProducts = value;
  }
};
