<template>
  <v-row>
    <v-col cols="12" v-for="item of currentCampaign" :key="item.title">
      <v-col lg="12" sm="12" cols="12" class="align-self-start">
        <v-card>
          <v-row dense justify="center" align-content="center">
            <v-col lg="4" sm="12" class="my-2">
              <v-img style="max-width: 150px;" class="mx-auto" :src="item.keyVisual"></v-img>
            </v-col>
            <v-col lg="8" sm="12" class="">
              <v-card-title>{{ `${item.id} - ${item.title}` }}</v-card-title>
              <v-card-text>
                <p v-if="item.dashboardTitle" class="text--primary text-base">
                  <b>Dashboard Title: </b> {{ item.dashboardTitle }}
                </p>
                <p class="text--primary text-base">
                  {{ getDateFormat(item.startDate) }} - {{ getDateFormat(item.endDate) }}
                </p>
                <p class="text--primary text-base">
                  <b>Description: </b>{{ item.readMe }}
                </p>
              </v-card-text>

            </v-col>
          </v-row>
          <v-card-text>
            <p class="text--primary text-base">
              <b>Products:</b>
            </p>
            <v-data-table
              :headers="productsHeaders"
              :disable-pagination="true"
              :hide-default-footer="true"
              :items="getProducts(item.id)"
              dense
            >
            </v-data-table>
          </v-card-text>
          <v-card-text>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>
                      Cashback
                    </th>
                    <th>
                      Minimum product limit
                    </th>
                    <th>
                      Maximum product limit
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ getCashback(item.prize) }}</td>
                    <td>{{ item.productsConfig.minProductsLimit }}</td>
                    <td>{{ item.productsConfig.maxProductsLimit }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-text>
            <span class="text--primary text-base">
              <b>Categories: </b>
              <span v-for="(item, idx) in item.category" :key="idx">{{ item }}, </span>
            </span>
          </v-card-text>
          <v-card-text>
            <p class="text--primary text-base" v-if="item.usersCategory">
              <b>User category: </b>{{ item.usersCategory }}
            </p>
            <p class="text--primary text-base" v-if="item.usersCategoryDescription">
              <b>User category Description: </b>{{ item.usersCategoryDescription }}
            </p>
          </v-card-text>
          <v-card-text>
            <v-data-table
              :headers="consumerHeaders"
              :disable-pagination="true"
              :hide-default-footer="true"
              :items="[]"
              dense
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  data() {
    const campaigns = localStorage.campaigns ? JSON.parse(localStorage.campaigns) : [];
    const currentCampaign = campaigns.filter(item => this.campaignIds.includes(item.id));
    const products = localStorage.products ? JSON.parse(localStorage.products) : [];

    return {
      currentCampaign,
      products,
      productsHeaders: [
        { text: "EAN", value: 'ean', },
        { text: "Name", value: "name" },
      ],
      consumerHeaders: [
        { text: "Max consumer limit", value: 'maxConsumer', },
        { text: "Max prize limit", value: "maxPrize" },
        { text: "Min prize limit", value: "minPrize" },
      ],
    };
  },
  methods: {
    getDateFormat(date) {
      return moment.parseZone(date).format("DD.MM.YYYY");
    },
    getProducts(campaignId) {
      return this.products.filter(item =>
        this.currentCampaign.find(item2 => item2.id === campaignId).products.includes(item.id)
      );
    },
    getCashback(item) {
      return item.type == 'fixedCashback' ? (item.value + ' Euros') : (item.value + '%')
    }
  },
  props: ["campaignIds"]
};
</script>
