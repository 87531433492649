<template>
  <v-row justify="space-around">
    <v-btn :disabled="disableButton" v-if="!filterRole(['AGENTL3'])" @click="startProcessReceipt('REJECTED')"
      color="error">
      Reject
    </v-btn>
    <v-btn :disabled="disableButton" v-if="filterRole(['AGENTL1'])" @click="startProcessReceipt('SKIP')"
      color="warning">
      Skip Re-Evaluate
    </v-btn>
    <v-btn :disabled="disableButton" v-if="filterRole(['AGENTL1'])" @click="startProcessReceipt('REEVALUATE')"
      color="info">
      Re-Evaluate
    </v-btn>
    <v-btn :disabled="disableButton" v-if="!filterRole(['AGENTL1', 'AGENTL3'])"
      @click="startProcessReceipt('CONFIRMED')" color="success">
      Process
    </v-btn>
    <v-btn :disabled="disableButton" v-if="filterRole(['AGENT'])" @click="startProcessReceipt('GOODWILL')" color="info">
      Accept Goodwill
    </v-btn>
    <v-btn v-if="filterRole(['AGENTL3'])" @click="saveData()" color="success"> Save </v-btn>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    const user = JSON.parse(localStorage.getItem("user"));
    const rejectReasons = localStorage.rejectReasons ? JSON.parse(localStorage.rejectReasons) : [];
    const userRole = user.role;
    return {
      rejectReasons,
      userRole,
      disableButton: false
    };
  },
  computed: {
    ...mapGetters(["currentReceipt"])
  },
  methods: {
    ...mapActions(["processReceipt", "snackAlert", "updateReceiptDetails"]),
    filterRole(roles) {
      return roles.includes(this.userRole);
    },

    startProcessReceipt(status) {
      this.disableButton = true;
      if (status === "CONFIRMED" || status === "GOODWILL") {
        const required = ["receiptId", "retailer", "total", "receiptDateTime"];
        const missing = required.filter(x => !this.currentReceipt[x]);
        if (missing.length > 0) {
          this.snackAlert({
            message: `Missing ${missing.join(", ")}`
          });
          this.disableButton = false;
          return;
        }
        if (this.currentReceipt.retailer === 15) {
          this.snackAlert({
            message: `Missing retailer`
          });
          this.disableButton = false;
          return;
        }
      } else if (status === "REEVALUATE") {
        if (!this.currentReceipt.reevaluateReason) {
          this.snackAlert({
            message: `Missing re evaluation reason`
          });
          this.disableButton = false;
          return;
        }
      } else if (status === "SKIP") {

      } else {
        if (!this.currentReceipt.rejectReason) {
          this.snackAlert({
            message: `Missing reject reason`
          });
          this.disableButton = false;
          return;
        } else if (!this.rejectReasons.find(x => x.key == this.currentReceipt.rejectReason)) {
          this.snackAlert({
            message: `Reject reason not selected`
          });
          this.disableButton = false;
          return;
        }
      }

      const payload =
        status === "REJECTED"
          ? {
            receiptToken: this.currentReceipt.receiptToken,
            rejectReason: this.currentReceipt.rejectReason,
            fraud: this.currentReceipt.fraud
          }
          : status === "REEVALUATE"
            ? {
              receiptToken: this.currentReceipt.receiptToken,
              reclaimReason: this.currentReceipt.otherReason
                ? this.currentReceipt.otherReason
                : this.currentReceipt.reevaluateReason
            }
            : status === "SKIP"
              ? {
                receiptToken: this.currentReceipt.receiptToken
              } : {
                receiptToken: this.currentReceipt.receiptToken,
                receiptDateTime: this.currentReceipt.receiptDateTime,
                receiptId: this.currentReceipt.receiptId,
                total: this.currentReceipt.total.toString(),
                // country: this.currentReceipt.country,
                retailer: this.currentReceipt.retailer,
                location: this.currentReceipt.location,
                products: this.currentReceipt.products,
                otherProducts: this.currentReceipt.otherProducts,
                cutOutStatus: this.currentReceipt.cutOutStatus,
                campaignExtraCheckBoxes: this.currentReceipt.extraCheckBoxesStatus,
                voucherStatus: this.currentReceipt.voucherStatus,
                onlyPgProducts: this.currentReceipt.onlyPgProducts,
                fraud: this.currentReceipt.fraud
              };

      // If any of the products has more than 100€ price, show a warning for once
      if (
        !this.currentReceipt.errorCount &&
        !!this.currentReceipt.products &&
        status === "CONFIRMED"
      ) {
        for (const [key, value] of Object.entries(this.currentReceipt.products)) {
          const prices = value.map(e => parseFloat(e.price)).filter(e => e >= 100);
          if (prices.length > 0) {
            this.snackAlert({
              message: `You entered a high price, make sure it's correct. If correct, please process the receipt again.`
            });
            this.currentReceipt.errorCount = 1;
            this.disableButton = false;
            return;
          }
        }
      }

      this.processReceipt({
        status,
        payload
      }).then(res => {
        this.disableButton = false;
        if (res.response) {
          this.$store.commit("setCurrentReceipt", {});
          this.$store.commit("setUsersCampaigns", []);
          this.$store.commit("setShowDialogue", false);
          this.$store.commit("setAutoPilotMode", true);
          this.$store.commit("mutateEnableProducts", false);
          this.snackAlert({
            message: `Receipt ${status}`,
            type: "success"
          });
        } else {
          this.snackAlert({
            message: `Error ${status}`
          });
        }
      });
    },
    saveData() {
      const required = ["receiptId", "retailer", "total", "receiptDateTime"];
      const missing = required.filter(x => !this.currentReceipt[x]);
      if (missing.length > 0) {
        this.snackAlert({
          message: `Missing ${missing.join(", ")}`
        });
        return;
      }
      if (this.currentReceipt.retailer === 15) {
        this.snackAlert({
          message: `Missing retailer`
        });
        return;
      }
      const payload = {
        receiptToken: this.currentReceipt.receiptToken,
        receiptDateTime: this.currentReceipt.receiptDateTime,
        receiptId: this.currentReceipt.receiptId,
        total: this.currentReceipt.total.toString(),
        // // country: this.currentReceipt.country,
        retailer: this.currentReceipt.retailer,
        location: this.currentReceipt.location
      };

      this.updateReceiptDetails({
        payload
      }).then(res => {
        if (res.response) {
          this.$store.commit("setCurrentReceipt", {});
          this.$store.commit("setUsersCampaigns", []);
          this.$store.commit("setShowDialogue", false);
          this.$store.commit("setAutoPilotMode", true);
          this.$store.commit("mutateEnableProducts", false);
          this.snackAlert({
            message: `Receipt Saved`,
            type: "success"
          });
        } else {
          this.snackAlert({
            message: `Error Saved`
          });
        }
      });
    }
  }
};
</script>
