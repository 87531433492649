<template>
  <div>
    <v-checkbox v-if="hasCutOut" v-model="cutOutVerified" @change="onCutOutVerifiedChange" label="Cut out is correct?"
      hide-details class="me-3 mt-1">
    </v-checkbox>
    <v-checkbox v-if="hasVoucher" v-model="voucherVerified" @change="onVoucherVerifiedChange" label="Voucher is correct?"
      hide-details class="me-3 mt-1">
    </v-checkbox>
    <v-form ref="form" v-if="campaignExtraCheckBoxes">
        <v-checkbox v-for="item of campaignExtraCheckBoxes" :key="item.name" v-model="extraCheckBoxes[item.name]" @change="onExtraCheckBoxesChange(item.name, $event)"
          :label=item.name hide-details class="me-3 mt-1">
        </v-checkbox>
    </v-form>
    <v-autocomplete label="Products" :prepend-icon="icons.mdiFormatListText" :items="products" item-text="name"
      item-value="id" max-height="auto" search-input.sync="ean" multiple :filter="searchProduct" dense
      :disabled="disabled || !enableProducts">
      <template v-slot:item="data">
        <div style="width: 100%">
          <v-list-item @click.native.stop.prevent="addProduct(data)" class="d-flex justify-space-between">
            <div>
              <span style="display: inline-block; min-width: 18ch">{{ data.item.ean }}</span>
              <v-chip class="ma-2" color="blue" text-color="black">
                <v-avatar left class="blue darken-4">{{ data.item.count }}</v-avatar>
                <span class="black--text">{{ data.item.name }}</span>
              </v-chip>
            </div>
            <span>
              <v-btn icon @click.stop.prevent="addProduct(data)">
                <v-icon>{{ icons.mdiPlus }}</v-icon>
              </v-btn>
              <v-btn icon @click.stop.prevent="removeProduct(data)">
                <v-icon>{{ icons.mdiMinus }}</v-icon>
              </v-btn>
            </span>
          </v-list-item>
        </div>
      </template>
    </v-autocomplete>
    <v-data-table dense :items="campaignSelectedProducts" :disable-pagination="true" :hide-default-footer="true"
      :headers="headers" no-data-text="No Products Selected" :item-key="new Date().getMilliseconds().toString()"
      class="elevation-1" :disabled="disabled">
      <template v-slot:item.ean="{ item }">
        <div :class="item.isFeRequired ? 'red--text' : ''">
          {{ item.ean }}
        </div>
      </template>
      <template v-slot:item.name="{ item }">
        <div>
          {{ getProductName(item.ean) }}
        </div>
      </template>
      <template v-slot:item.price="{ item }" :disabled="true">
        <div>
          <v-text-field required @input="changePrice($event, item)" v-model="item.price"
            :disabled="disabled"></v-text-field>
        </div>
      </template>
      <template v-slot:item.action="{ item }" :disabled="disabled">
        <v-icon :disabled="disabled" small class="mr-2" @click="addProduct(item, true)">{{ icons.mdiPlus }}</v-icon>
        <v-icon :disabled="disabled" small @click="removeProduct(item, true)">{{ icons.mdiMinus }}</v-icon>
      </template>

      <template v-slot:item.rppData="{ item }">
        <div>
          <v-autocomplete :items="rppItems" v-model="item.rpp" @change="updateRpp($event, item)" dense>
            <template v-slot:selection="{ item }">
              {{ item.item }}
            </template>
            <template v-slot:item="{ item }">
              <div style="width: 100%">
                <v-list-item class="d-flex justify-space-between">
                  <div>
                    <span>{{ item.item }}</span>
                    <v-chip color="blue" text-color="black">
                      <span class="black--text">{{ item.price }}</span>
                    </v-chip>
                  </div>
                </v-list-item>
              </div>
            </template>
          </v-autocomplete>
        </div>
      </template>

    </v-data-table>
  </div>
</template>

<script>
import { mdiFormatListText, mdiPlus, mdiMinus } from "@mdi/js";

import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["currentReceipt", "enableProducts"]),
  },
  data() {
    const campaigns = localStorage.campaigns ? JSON.parse(localStorage.campaigns) : [];
    const currentCampaign = campaigns.find(item => item.id === this.campaign);
    const hasCutOut = currentCampaign.hasCutOut;
    const campaignExtraCheckBoxes = currentCampaign.campaignExtraCheckBoxes;
    const hasVoucher = currentCampaign.hasVoucher;
    const products = localStorage.products ? JSON.parse(localStorage.products) : [];
    const currentProducts = products.filter(item => currentCampaign.products.includes(item.id));
    const selectedProducts = this.$store.getters.currentReceipt.selectedProducts
      ? this.$store.getters.currentReceipt.selectedProducts
      : {};
    const hasCutOutStatus = this.$store.getters.currentReceipt.cutOutStatus
      ? this.$store.getters.currentReceipt.cutOutStatus
      : {};
    const hasVoucherStatus = this.$store.getters.currentReceipt.voucherStatus
      ? this.$store.getters.currentReceipt.voucherStatus
      : {};

    const hasCampaignExtraCheckBoxesStatus = this.$store.getters.currentReceipt.extraCheckBoxesStatus
      ? this.$store.getters.currentReceipt.extraCheckBoxesStatus
      : {};

    const campaignCutOutStatus = hasCutOutStatus[this.campaign]
      ? hasCutOutStatus[this.campaign]
      : false;

    const campaignVoucherStatus = hasVoucherStatus[this.campaign]
      ? hasVoucherStatus[this.campaign]
      : false;

    const campaignExtraCheckBoxesStatus = hasCampaignExtraCheckBoxesStatus[this.campaign] ? hasCampaignExtraCheckBoxesStatus[this.campaign] : {};

    const campaignSelectedProducts =
      selectedProducts && selectedProducts[this.campaign] ? selectedProducts[this.campaign] : [];

    const receiptProducts = this.$store.getters.currentReceipt.products
      ? this.$store.getters.currentReceipt.products
      : {};

    const campaignReceiptProducts =
      receiptProducts && receiptProducts[this.campaign] ? receiptProducts[this.campaign] : [];

    const rppItems = this.$store.getters.currentReceipt.rppData
      ? this.$store.getters.currentReceipt.rppData.concat([{ item: 'none' }])
      : ['none'];
    return {
      products: currentProducts,
      hasCutOut,
      hasVoucher,
      campaignExtraCheckBoxes,
      voucherVerified: campaignVoucherStatus,
      cutOutVerified: campaignCutOutStatus,
      extraCheckBoxes: campaignExtraCheckBoxesStatus,
      campaignSelectedProducts: campaignReceiptProducts || campaignSelectedProducts || [],
      icons: {
        mdiFormatListText,
        mdiPlus,
        mdiMinus
      },
      headers: [
        {
          text: "EAN",
          align: "left",
          sortable: false,
          value: "ean"
        },
        {
          text: "Product",
          align: "left",
          sortable: false,
          value: "name"
        },
        {
          text: "Price",
          align: "left",
          sortable: false,
          value: "price"
        },
        {
          text: "Count",
          align: "left",
          sortable: false,
          value: "count"
        },
        {
          text: "Actions",
          value: "action",
          sortable: false
        },
        {
          text: "RPP",
          value: "rppData",
          sortable: false,
          width: "30%"
        },
      ],
      rppItems
    };
  },
  watch: {
    selected(foundProducts) {
      const foundIndexes = foundProducts.findIndex(p => p.campaign === this.campaign)
      if (foundIndexes > -1) {
        this.campaignSelectedProducts = [{ ean: foundProducts[foundIndexes].barcode, price: foundProducts[foundIndexes].oPrice, count: 1 }]
      }
    }
  },
  methods: {
    searchProduct(item, query) {
      return item.ean.toLowerCase().includes(query.toLowerCase()) || item.name.toLowerCase().includes(query.toLowerCase());;
    },
    addProduct(data, status) {
      const product = status ? data : data.item;
      const isFound = this.campaignSelectedProducts.findIndex(item => item.ean === product.ean);
      if (isFound >= 0) {
        this.campaignSelectedProducts[isFound].count++;
      } else {
        this.campaignSelectedProducts.push({
          ean: product.ean,
          name: product.name,
          count: 1,
          price: product.price || 0,
          rpp: '',
          cutOutVerified: this.cutOutVerified,
          voucherVerified: this.voucherVerified,
          extraCheckBoxes: this.extraCheckBoxes
        });
      }
      this.currentReceipt.products = this.currentReceipt.products || {};
      this.currentReceipt.products[this.campaign] = this.campaignSelectedProducts;
    },
    removeProduct(data, status) {
      const product = status ? data : data.item;
      const isFound = this.campaignSelectedProducts.findIndex(item => item.ean === product.ean);
      if (isFound >= 0) {
        if (this.campaignSelectedProducts[isFound].count > 1) {
          this.campaignSelectedProducts[isFound].count--;
        } else {
          this.campaignSelectedProducts.splice(isFound, 1);
        }
      }
      this.currentReceipt.products[this.campaign] = this.campaignSelectedProducts;
    },
    changePrice(event, product) {
      const isFound = this.campaignSelectedProducts.findIndex(item => item.ean === product.ean);
      if (isFound >= 0) {
        this.campaignSelectedProducts[isFound].price = event;
      }
      this.currentReceipt.products[this.campaign] = this.campaignSelectedProducts;
    },
    updateRpp(event, product) {
      const isFound = this.campaignSelectedProducts.findIndex(item => item.ean === product.ean);
      if (isFound >= 0) {
        this.campaignSelectedProducts[isFound].rpp = event;
      }
      this.currentReceipt.products[this.campaign] = this.campaignSelectedProducts;
    },
    getProductName(ean) {
      return this.products.find(item => item.ean == parseInt(ean)).name
    },
    onCutOutVerifiedChange(event) {
      this.currentReceipt.cutOutStatus = this.currentReceipt.cutOutStatus || {};
      this.currentReceipt.cutOutStatus[this.campaign] = event;
    },
    onVoucherVerifiedChange(event) {
      this.currentReceipt.voucherStatus = this.currentReceipt.voucherStatus || {};
      this.currentReceipt.voucherStatus[this.campaign] = event;
    },
    onExtraCheckBoxesChange(name, event) {
      this.currentReceipt.extraCheckBoxesStatus = this.currentReceipt.extraCheckBoxesStatus || {};
      this.currentReceipt.extraCheckBoxesStatus[this.campaign] = this.currentReceipt.extraCheckBoxesStatus[this.campaign] || {};
      this.currentReceipt.extraCheckBoxesStatus[this.campaign][name] = event;
    }
  },
  props: ["campaign", "disabled", "selected"]
};
</script>
