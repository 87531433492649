<template>
  <v-col v-if="userRole !== 'OPERATOR' && JSON.stringify(deviceInfo) !== '{}'">
    <v-card>
      <v-row dense justify="center" align-content="center">
      <v-col lg="4" sm="12">
        <v-img
        justify="center"
         align-content="center"
          :src="deviceInfo.platform === 'ANDROID' ? require('@/assets/images/misc/androidLogo.png') : require('@/assets/images/misc/iosLogo.png')"
          max-height="250px"
          max-width="250px"
          class="mt-8 ml-6 mr-4"
        ></v-img>        </v-col>
        <v-col lg="8" sm="12">
          <v-card-title>DEVICE INFO</v-card-title>
          <v-card-text>
            <p class="text--primary text-base">PLATFORM: {{ deviceInfo.platform }}</p>
            <p class="text--primary text-base">VERSION: {{ deviceInfo.appVersion }}</p>
            <div :class="`text-xs success--text`"  v-if="!deviceInfo.isLastVersion">Last Verison: {{ deviceInfo.lastVersion }}</div>
            <p class="text--primary text-base">DEVICE: {{ deviceInfo.deviceName }}</p>
            <p class="text--primary text-base">CONSUMER ID: {{ deviceInfo.consumerId }}</p>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["userRole", "deviceInfo"]),
  },
};
</script>
