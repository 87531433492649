<template>
    <div v-if="usedReceiptImageUrl" class="viewer image-container">
        <span style="display:inline-block;min-width:18ch;">{{ usedRereferenceNumber }}</span>
        <v-container>
            <v-flex>
            </v-flex>
            <v-row>
                <v-flex md12 class="image-container">
                    <div class="images" v-viewer="{ inline: true, button: true, fullscreen: false, navbar: false }">
                        <img style="width: 100%;" crossorigin='anonymous' :src="usedReceiptImageUrl">
                    </div>
                </v-flex>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
export default {
    data: () => {
        return {
        }
    },

    props: ["usedReceiptImageUrl", "usedRereferenceNumber"],
    methods: {
        inited(viewer) {
            this.$viewer = viewer
        },
        show() {
            this.$viewer.show()
        },
    },
};
</script>
<style lang="scss" scoped>
.image-container {
    overflow: hidden;
    height: 100%;
}

.topButtons {
    z-index: 12;
    padding: 0 !important;

    .col-6,
    .col-12 {
        padding: 0 !important;
    }
}
</style>