<template>
  <v-col v-if="userRole !== 'OPERATOR' && processHistory !== []">
    <v-data-table :headers="processHistoryHeaders" :disable-pagination="true" :hide-default-footer="true"
      :items="processHistory" dense>
      <template v-slot:item.campaignId="{ item }">
        {{ getCampaignName(item.campaignId) }}
      </template>
      <template v-slot:item.output="{ item }">
        {{ getOutput(item.output) }}
      </template>
      <template v-slot:item.dashboarderId="{ item }">
        <span v-if="userRole !== 'AGENTL1'">{{ item.dashboarderId }}</span>
      </template>
    </v-data-table>
  </v-col>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    const campaigns = localStorage.campaigns ? JSON.parse(localStorage.campaigns) : [];

    return {
      campaigns,
      processHistoryHeaders: [
        { text: "Campaign", value: 'campaignId', },
        { text: "Date", value: "updatedAt" },
        { text: "Action", value: "action", width: "10%" },
        { text: "Cause/CB", value: "output" },
        { text: "Dash. ID", value: "dashboarderId", width: "5%" },
      ],
    };
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["userRole", "processHistory"])
  },
  methods: {
    getDateTime(date) {
      return moment.parseZone(date).format("MMMM Do YYYY, HH:mm:ss");
    },
    getCampaignName(campaignId) {
      const campaign = this.campaigns.find(campaign => campaign.id == campaignId)
      return campaign.dashboardTitle ? `${campaignId} - ${campaign.dashboardTitle}` : `${campaignId} - ${campaign.title}`
    },
    getOutput(output) {
      return Object.keys(output)[0] + ": " + Object.values(output)[0]
    },
  }
};
</script>
