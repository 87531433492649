<template>
  <v-col v-if="userRole !== 'OPERATOR' && paymentHistory !== []">
    <v-card v-for="(item, idx) of paymentHistory" :key="idx">
      <div>
        <v-expansion-panels class="my-3 w-100">
          <v-expansion-panel>
            <v-expansion-panel-header class="d-flex flex-column">
              <h3 class="mb-3">{{ `Ref: ${item.paymentReferenceNumber} - ${getPaymentDate(item)} - ${item.paymentAmount} - ${item.paymentProvider}` }}
                <v-chip :color="getColor(item.status)" class="pill">
                  {{ item.status }}
                </v-chip>
              </h3>
              <br>
              <h4>{{ `Recipient - ${item.recipient.fullName} - ${item.recipient.iban} - ${item.recipient.bic}` }}
              </h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="walletHeaders"
                :disable-pagination="true"
                :hide-default-footer="true"
                :items="item.wallets"
                dense
              >
                <template v-slot:item.walletCreatedAt="{ item }">
                    {{ getDateTime(item.walletCreatedAt) }}
                </template>
                <template v-slot:item.walletReceipt="{ item }">
                    {{ item.walletReceipt.referenceNumber }}
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-card>
  </v-col>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    const campaigns = localStorage.campaigns ? JSON.parse(localStorage.campaigns) : [];

    return {
      campaigns,
      walletHeaders: [
        { text: "ID", value: 'walletId', },
        { text: "Payment amount", value: "walletPaymentAmount" },
        { text: "Status", value: "walletStatus" },
        { text: "Request date", value: "walletCreatedAt" },
        { text: "Reference number", value: "walletReceipt" },
      ]
    };
  },
  computed: {
    ...mapGetters(["userRole", "paymentHistory"]),
  },
  methods: {
    getDateTime(date) {
      return moment.parseZone(date).format("DD/MM/YYYY, HH:mm:ss");
    },
    getPaymentDate(item) {
      if (item.paymentDate) return `Payment: ${moment.parseZone(item.paymentDate).format("DD/MM/YYYY, HH:mm:ss")}`
      else return `Request: ${moment.parseZone(item.createdAt).format("DD/MM/YYYY, HH:mm:ss")}`
    },
    getColor(status) {
      if (status === "PAID") return "success";
      else if (status === "REJECTED" || status === "ERROR") return "error";
      else if (status === "PRE-CONFIRMED") return "accent";
      else if (status === "RE-EVALUATION") return "info";
      else return "secondary";
    }
  },
};
</script>
