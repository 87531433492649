export default {
  snackBar: state => state.snackBar,
  showSnackBar: state => state.showSnackBar,
  isAuthenticated: state => state.isAuthenticated,
  userToken: state => state.userToken,
  userRole: state => state.userRole,
  userName: state => state.userName,
  darkTheme: state => state.darkTheme,
  autoPilotMode: state => state.autoPilotMode,
  currentReceipt: state => state.currentReceipt,
  showDialogue: state => state.showDialogue,
  prevPartcipations: state => state.prevPartcipations,
  usedReceiptImageUrl: state => state.usedReceiptImageUrl,
  usedRereferenceNumber: state => state.usedRereferenceNumber,
  processHistory: state => state.processHistory,
  paymentHistory: state => state.paymentHistory,
  usersCampaigns: state => state.usersCampaigns,
  deviceInfo: state => state.deviceInfo,
  routes: state => state.routes,
  enableProducts: state => state.enableProducts,
};
